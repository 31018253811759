<template>
  <div class="users-list">
    <template v-if="isMobileDevice">
      <div v-if="items.length">
        <CustomersCard
          v-for="item of items"
          :id="item.id"
          :key="item.id"
          :status="item.status"
          :employee="item.user"
          :contacts="item.contacts"
          :customer="item.customer"
          :is-dealer-users="true"
          :visit="item.visit"
          @clickOnOrder="onClickRow({ id: item.id })"
        />
      </div>
      <div v-else>{{ $t("page.dashboard.emptyMessage") }}</div>
    </template>

    <OurTable
      v-else
      :headers="headers.length ? headers : tableHeaders"
      :items="items"
      :table-fixed="false"
      class="users-list-table"
      @clickRow="onClickRow"
    >
      {{ items }}
      <template #cell-contacts="{ value }">
        <div class="contacts-block">
          <div>{{ value.phone }}</div>
          <OurLink
            class="email"
            :text="value.email"
            :url="value.email"
            type="email"
            size="xs"
            color="gray"
            dashed
            no-focus-ring
            @click.native.stop
          />
        </div>
      </template>

      <template #cell-company="{ value }">
        <div class="company-block">
          <div class="text">{{ value }}</div>
        </div>
      </template>

      <template #cell-status="{ value }">
        <OurTag size="md" :text="value?.text" :color="value?.color" />
      </template>

      <template v-if="getPermission['dealer.impersonate']" #cell-buttons="{ value }">
        <OurLink no-focus-ring @click.native.stop="onClickHelperMenu(value)">
          <div class="user-row relative">
            <OurSvgIcon :src="icons.more" />

            <div v-if="isShownMenuBlock === value" class="menu-block">
              <a href="" @click.prevent="loginImpersonateHandler(value)">
                {{ $t("page.users.enter") }}
              </a>
            </div>
          </div>
        </OurLink>
      </template>
    </OurTable>

    <OurPagination
      v-if="isMoreThanOnePage"
      :current-page="paginationData.currentPage"
      :per-page="paginationData.perPage"
      :total="paginationData.total"
      @pageChange="onPageChange"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import MainSubMenu from "@/layouts/_bit/admin-mono/MainSubMenu.vue";
import CustomersCard from "@/components/MobileCards/CustomersCard.vue";

export default {
  name: "UsersList",
  components: { CustomersCard, MainSubMenu },

  props: {
    items: {
      type: Array,
      default: () => [],
    },

    headers: {
      type: Array,
      default: () => [],
    },

    paginationData: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      isShownMenuBlock: null,
    };
  },

  computed: {
    ...mapGetters("breakpoint", ["isMobileDevice"]),
    ...mapGetters("user", ["isUserDealer", "getPermission", "userProfileName"]),
    icons: () => ({
      more: require("@material-symbols/svg-400/outlined/more_horiz-fill.svg"),
    }),
    tableHeaders() {
      const data = [
        {
          value: "id",
          text: this.$t("page.users.id"),
          thClass: "w-24",
          tdClass: "max-w-[2rem] min-w-[5rem]",
        },
        {
          value: "user",
          text: this.$t("page.users.user"),
          thClass: "w-[5rem]",
          tdClass: "max-w-[10rem] min-w-[10rem]",
        },
        {
          value: "contacts",
          text: this.$t("page.users.contacts"),
          thClass: "w-[10rem]",
          tdClass: "max-w-[12rem] min-w-[10rem]",
        },
        {
          value: "customer",
          text: this.$t("page.users.customer"),
          thClass: "w-[10rem]",
          tdClass: "max-w-[12rem] min-w-[10rem]",
        },
        {
          value: "visit",
          text: this.$t("page.users.visit"),
          thClass: "w-[8rem]",
          tdClass: "max-w-[10rem] min-w-[8rem]",
        },
        {
          value: "status",
          text: this.$t("page.fleaMarket.status"),
          thClass: "w-[7rem]",
          tdClass: "max-w-[9rem] min-w-[7rem]",
        },
      ];

      if (!this.isUserDealer && this.getPermission["dealer.impersonate"]) {
        data.push({
          value: "buttons",
          thClass: "w-[4rem]",
          tdClass: "max-w-[4rem]",
        });
      }

      return data;
    },

    isMoreThanOnePage() {
      return this.paginationData.lastPage > 1;
    },
  },

  created() {
    window.addEventListener("click", (event) => this.closeMenuBlock(event));
  },

  destroyed() {
    window.removeEventListener("click", (event) => this.closeMenuBlock(event));
  },

  methods: {
    ...mapActions(["loginImpersonate"]),

    async loginImpersonateHandler(val) {
      window.localStorage.setItem("name", this.userProfileName);
      await this.$router.push({ name: "Dashboard" });
      await this.loginImpersonate(val);
      this.$router.go();
    },

    onPageChange(page) {
      this.$emit("pageChange", page);
    },

    onClickRow(row) {
      this.$emit("onClickRow", row.id);
    },

    closeMenuBlock(event) {
      if (!this.$el.contains(event.target)) {
        this.isShownMenuBlock = false;
      }
    },

    onClickHelperMenu(val) {
      if (this.isShownMenuBlock === val) {
        this.isShownMenuBlock = null;

        return;
      }

      this.isShownMenuBlock = val;
    },
  },
};
</script>

<style lang="postcss" scoped>
.users-list {
  .contacts-block,
  .company-block {
    @apply overflow-hidden;

    .email,
    .text {
      @apply truncate;
    }
  }
}

.menu-block {
  @apply absolute right-0 top-6;
  @apply h-auto;
  @apply rounded-lg border border-gray-700 bg-gray-900 text-white;
  @apply py-1;
  @applu flex flex-col;
  a {
    @apply block whitespace-nowrap px-3 py-2;
    &:hover {
      @apply bg-gray-800;
    }
  }
}

.user-row {
  @apply flex h-8 w-8 items-center justify-center rounded transition-all hover:bg-background-light;
}

.user-row:last-child {
  .menu-block {
    top: -40%;
    right: 110%;
  }
}
</style>
