import i18n from "@/plugins/vue-i18n";
import DataService from "@/services/data.service";

export default {
  namespaced: true,
  state: {
    status: {
      new: {
        text: i18n.t("leads.status.new"),
        color: "blue",
        value: "new",
      },
      inWork: {
        text: i18n.t("leads.status.inWork"),
        color: "yellow",
        value: "inWork",
      },
      visitSale: {
        text: i18n.t("leads.status.visitSale"),
        color: "yellow",
        value: "visitSale",
      },
      objectVisit: {
        text: i18n.t("leads.status.objectVisit"),
        color: "yellow",
        value: "objectVisit",
      },
      commercialOffer: {
        text: i18n.t("leads.status.commercialOffer"),
        color: "yellow",
        value: "commercialOffer",
      },
      pending: {
        text: i18n.t("leads.status.pending"),
        color: "yellow",
        value: "pending",
      },
      contractSigning: {
        text: i18n.t("leads.status.contractSigning"),
        color: "yellow",
        value: "contractSigning",
      },
      equipmentSupply: {
        text: i18n.t("leads.status.equipmentSupply"),
        color: "yellow",
        value: "equipmentSupply",
      },
      installation: {
        text: i18n.t("leads.status.installation"),
        color: "yellow",
        value: "installation",
      },
      closing: {
        text: i18n.t("leads.status.closing"),
        color: "yellow",
        value: "closing",
      },
      feedback: {
        text: i18n.t("leads.status.feedback"),
        color: "yellow",
        value: "feedback",
      },
      done: {
        text: i18n.t("leads.status.done"),
        color: "green",
        value: "done",
      },
      failed: {
        text: i18n.t("leads.status.failed"),
        color: "red",
        value: "failed",
      },
      notReserved: {
        text: i18n.t("leads.status.notReserved"),
        color: "yellow",
        value: "notReserved",
      },
    },
    allLeadsFiltersForm: null,
    ownLeadsFiltersForm: null,
  },
  getters: {
    getStatus(state) {
      return (status) => {
        return state.status[status];
      };
    },

    getFiltersOptions(state) {
      return (isAll) => {
        const filtersForm = isAll ? state.allLeadsFiltersForm : state.ownLeadsFiltersForm;
        const filtersOptions = [];

        if (filtersForm) {
          const { date, region: regionId, status, description } = filtersForm;
          const preparedStatus = status ? DataService.getPreparedStatus(status) : "";

          if (Object.values(date).length) {
            filtersOptions.push({
              method: "where",
              column: "creationDate",
              operator: "date>=",
              value: date.from.toString(),
            });

            filtersOptions.push({
              method: "where",
              column: "creationDate",
              operator: "date<=",
              value: date.to.toString(),
            });
          }

          if (regionId) {
            filtersOptions.push({
              method: "where",
              column: "regionId",
              operator: "",
              value: regionId,
            });
          }

          if (status) {
            filtersOptions.push({
              method: "where",
              column: "status",
              operator: "like",
              value: preparedStatus,
            });
          }

          if (description) {
            filtersOptions.push({
              method: "where",
              column: "description",
              operator: "like",
              value: description,
            });
          }
        }

        return filtersOptions;
      };
    },
  },
  mutations: {
    SET_FILTERS_FORM(state, data) {
      const { isAll, form } = data;

      if (isAll) state.allLeadsFiltersForm = form;
      else state.ownLeadsFiltersForm = form;
    },
  },
};
