<template>
  <OurPage :title="$t('title.news.edit')" :back-route="backRoute" width="md">
    <DeleteModal
      :show.sync="isShownDeleteModal"
      :user-id="newsId"
      :full-name="form.name"
      :mutation="deleteNewsMutation()"
      @deleteUser="goToList"
    />
    <NewsForm
      ref="newsForm"
      v-model="form"
      :upload-files="uploadFiles"
      :type="imageType"
      @uploadFiles="uploadFilesMutation"
      @deleteFiles="deleteFileMutation"
    />
    <template #footer-left>
      <OurButton
        v-if="getPermission['news.update']"
        class="button"
        :text="$t('button.save')"
        @click="onClickSaveButton"
      />

      <OurLink :route="backRoute">
        <OurButton class="button" :text="$t('button.back')" variant="secondary" />
      </OurLink>
    </template>

    <template #footer-right>
      <OurButton
        v-if="getPermission['news.delete']"
        class="button"
        variant="thirdary"
        :text="$t('button.remove')"
        @click="onClickDeleteButton"
      />
    </template>
  </OurPage>
</template>

<script>
import DeleteModal from "@/components/modals/DeleteModal.vue";
import apolloMixin from "@/mixins/apollo.mixin";
import { mapGetters } from "vuex";
import { newsQuery } from "@/views/settings/news/edit/gql/queries";
import NewsForm from "@/views/settings/news/add/_components/NewsForm.vue";
import {
  deleteFileMutation,
  uploadFilesMutation,
} from "@/views/settings/base-knowledge/chapter/add/gql/mutations";
import { deleteNewsMutation, updateNewsMutation } from "@/views/settings/news/edit/gql/mutations";

export default {
  name: "NewsEdit",
  components: { NewsForm, DeleteModal },
  mixins: [apolloMixin],
  data() {
    return {
      isShownDeleteModal: false,
      backRoute: {
        name: "SettingsNews",
        title: this.$t("title.news._"),
      },
      form: {
        name: "",
        description: "",
        content: "",
        previewImage: {},
        mainImage: null,
        banner: null,
        isActive: true,
        showBanner: false,
        publishedAt: "",
      },
      allFiles: [],
      uploadFiles: [],
      imageType: "",
    };
  },
  computed: {
    ...mapGetters("user", ["getPermission"]),
    newsId() {
      return this.$route.params.id;
    },
  },
  created() {
    this.getData();
  },
  methods: {
    deleteNewsMutation() {
      return deleteNewsMutation;
    },
    goToList() {
      this.$router.push({ name: "SettingsNews" });
    },
    onClickDeleteButton() {
      this.isShownDeleteModal = true;
    },
    async uploadFilesMutation(object) {
      const { uploadFiles: files } = await this.$post(
        uploadFilesMutation,
        { files: object.selectedFiles, collection: object.type },
        { withLoader: false, withNotify: false }
      );

      this.uploadFiles = files;
      this.imageType = object.type;
    },

    async deleteFileMutation(id) {
      await this.$post(deleteFileMutation, { id }, { withLoader: false, withNotify: false });
    },
    async getData() {
      const newsId = this.newsId;
      const { news } = await this.$get(newsQuery, { newsId: newsId });

      if (news) {
        const {
          isActive,
          name,
          publishedAt,
          description,
          content,
          showBanner,
          banner,
          mainImage,
          previewImage,
        } = news;

        this.form = {
          id: newsId,
          name,
          isActive,
          description,
          content,
          publishedAt: parseInt(publishedAt),
          showBanner,
          banner,
          mainImage,
          previewImage,
        };
      }
    },
    async onClickSaveButton() {
      this.$refs.newsForm.emitForm();
      if (this.form.mainImage) this.allFiles.push(this.form.mainImage);
      if (this.form.banner) this.allFiles.push(this.form.banner);
      this.allFiles.push(this.form.previewImage);

      if (this.form.isValid) {
        this.form.files = { sync: this.allFiles };
        const form = {
          ...this.form,
        };

        const { updateNews } = await this.$post(updateNewsMutation, form, {
          delaySuccessNotify: true,
        });

        if (updateNews.id) this.goToList();
      }
    },
  },
};
</script>
