import i18n from "@/plugins/vue-i18n";
import { STATUS_TYPE } from "@/api/orders";

export default {
  namespaced: true,
  state: {
    deliveryStatus: {
      new: {
        text: i18n.t("orders.deliveryStatus.new"),
        color: "red",
        value: "new",
      },
      notShipped: {
        text: i18n.t("orders.deliveryStatus.notShipped"),
        color: "orange",
        value: "notShipped",
      },
      partiallyShipped: {
        text: i18n.t("orders.deliveryStatus.partiallyShipped"),
        color: "violet",
        value: "partiallyShipped",
      },
      shipped: {
        text: i18n.t("orders.deliveryStatus.shipped"),
        color: "blue",
        value: "shipped",
      },
      reserved: {
        text: i18n.t("orders.deliveryStatus.reserved"),
        color: "yellow",
        value: "reserved",
      },
      canceled: {
        text: i18n.t("orders.deliveryStatus.canceled"),
        color: "red",
        value: "canceled",
      },
      draft: {
        text: i18n.t("orders.deliveryStatus.draft"),
        color: "gray",
        value: "draft",
      },
    },
    payStatus: {
      notPaid: {
        text: i18n.t("orders.payStatus.notPaid"),
        color: "yellow",
        value: "notPaid",
      },
      contract: {
        text: i18n.t("orders.payStatus.contract"),
        color: "blue",
        value: "contract",
      },
      partiallyPaid: {
        text: i18n.t("orders.payStatus.partiallyPaid"),
        color: "violet",
        value: "partiallyPaid",
      },
      paid: {
        text: i18n.t("orders.payStatus.paid"),
        color: "green",
        value: "paid",
      },
    },
    shipmentStatus: {
      preparing: {
        text: i18n.t("orders.shipmentStatus.preparing"),
        color: "yellow",
        value: "preparing",
      },
      ready: {
        text: i18n.t("orders.shipmentStatus.ready"),
        color: "green",
        value: "ready",
      },
      shipped: {
        text: i18n.t("orders.shipmentStatus.shipped"),
        color: "blue",
        value: "shipped",
      },
      allowed: {
        text: i18n.t("orders.shipmentStatus.allowed"),
        color: "violet",
        value: "allowed",
      },
      attention: {
        text: i18n.t("orders.shipmentStatus.attention"),
        color: "red",
        value: "attention",
      },
    },
    type: {
      incomingPaymentOrder: i18n.t("orders.type.incomingPaymentOrder"),
      incomingCashOrder: i18n.t("orders.type.incomingCashOrder"),
      debtAdjustment: i18n.t("orders.type.debtAdjustment"),
      // notSet: i18n.t("orders.type.notSet"),
      goodsServicesSale: i18n.t("orders.type.goodsServicesSale"),
      goodsReturnFromCustomer: i18n.t("orders.type.goodsReturnFromCustomer"),
      outgoingPaymentOrder: i18n.t("orders.type.outgoingPaymentOrder"),
      outgoingCashOrder: i18n.t("orders.type.outgoingCashOrder"),
      goodsReceipt: i18n.t("orders.type.goodsReceipt"),
      goodsWriteOff: i18n.t("orders.type.goodsWriteOff"),
      goodsServicesReceipt: i18n.t("orders.type.goodsServicesReceipt"),
      goodsReturnToSupplier: i18n.t("orders.type.goodsReturnToSupplier"),
    },
    category: {
      cashless: i18n.t("orders.category.cashless"),
      cash: i18n.t("orders.category.cash"),
    },
    ordersFiltersForm: null,
    draftsFiltersForm: null,
    orderProducts: [],
    searchForm: {
      category: {},
      search: "",
      contract: "",
      name: "",
      status: "",
    },
    filtersForm: {
      name: "",
      status: "",
    },
  },

  getters: {
    getStatus(state) {
      return (status, type, partiallyShipped) => {
        if (type === STATUS_TYPE.delivery) {
          if (status === "partiallyShipped" && partiallyShipped) {
            const { text, color, value } = state.deliveryStatus[status];

            return {
              text: `${text} ${partiallyShipped}`,
              color,
              value,
            };
          }

          return state.deliveryStatus[status];
        } else if (type === STATUS_TYPE.pay) {
          return state.payStatus[status];
        } else {
          return state.shipmentStatus[status];
        }
      };
    },

    getDeliveryStatuses(state) {
      const status = { ...state.deliveryStatus };

      delete status.draft;

      return Object.values(status);
    },

    getPayStatuses(state) {
      return Object.values(state.payStatus);
    },

    getType(state) {
      return (type) => {
        return state.type[type];
      };
    },

    getTypes(state) {
      return Object.entries(state.type).map((type) => {
        const [code, label] = type;

        return { code, label };
      });
    },

    getCategory(state) {
      return (category) => {
        return state.category[category];
      };
    },

    getFiltersOption() {
      return (column, value) => {
        const columns = ["status", "payStatus", "companyId"];
        const operator = columns.includes(column) ? "" : "like";
        const option = {
          method: "where",
          column,
          operator,
          value,
        };

        if (column === "companyId") {
          option.relation = "company";
          option.column = "companies.id";
        }

        return option;
      };
    },

    getFiltersOptions(state, getters) {
      return (isOrders) => {
        const filtersForm = isOrders ? state.ordersFiltersForm : state.draftsFiltersForm;
        let filtersOptions = [];

        if (isOrders) {
          filtersOptions.push({
            method: "where",
            column: "status",
            operator: "!=",
            value: "draft",
          });
        } else {
          filtersOptions.push({
            method: "where",
            column: "status",
            operator: "=",
            value: "draft",
          });
        }

        if (filtersForm) {
          Object.keys(filtersForm).forEach((key) => {
            if (filtersForm[key] && key !== "date" && key !== "amount") {
              const column = key;
              const value = filtersForm[key];

              filtersOptions.push(getters.getFiltersOption(column, value));
            }
          });

          if (Object.values(filtersForm.date).length) {
            filtersOptions.unshift({
              method: "where",
              column: isOrders ? "documentDate" : "updatedAt",
              operator: "date>=",
              value: filtersForm.date.from.toString(),
            });

            filtersOptions.unshift({
              method: "where",
              column: isOrders ? "documentDate" : "updatedAt",
              operator: "date<=",
              value: filtersForm.date.to.toString(),
            });
          }

          if (filtersForm.amount.from) {
            filtersOptions.unshift({
              method: "where",
              column: "amount",
              operator: ">=",
              value: filtersForm.amount.from,
            });
          }

          if (filtersForm.amount.to) {
            filtersOptions.unshift({
              method: "where",
              column: "amount",
              operator: "<=",
              value: filtersForm.amount.to,
            });
          }
        }

        return filtersOptions;
      };
    },
  },

  mutations: {
    SET_FILTERS_FORM(state, data) {
      const { isOrders, form } = data;

      if (isOrders) state.ordersFiltersForm = form;
      else state.draftsFiltersForm = form;
    },

    SET_PRODUCTS(state, products) {
      state.orderProducts = products;
    },

    SET_PRODUCT_QUANTITY(state, [productId, newProductQuantity]) {
      const { orderProducts } = state;
      const index = orderProducts.findIndex((item) => item.id === productId);
      const product = orderProducts[index];

      product["quantity"] = newProductQuantity;
      orderProducts.splice(index, 1, product);
    },

    SET_CATEGORY(state, category) {
      state.searchForm.category = category;
    },

    SET_SEARCH(state, search) {
      state.searchForm.search = search;
    },

    SET_PRODUCTS_FILTERS_FORM(state, form) {
      state.filtersForm = form;
    },

    ADD_PRODUCT_TO_ORDER(state, [product, quantity]) {
      const { orderProducts } = state;
      const index = orderProducts.findIndex((item) => item.id === product.id);

      if (orderProducts[index]?.id === product.id) {
        const product = orderProducts[index];

        product["quantity"] += quantity;

        orderProducts.splice(index, 1, product);
      } else {
        product.quantity = quantity;
        orderProducts.push(product);
      }
    },

    DELETE_PRODUCT_FROM_ORDER(state, productId) {
      const { orderProducts } = state;
      const index = orderProducts.findIndex((item) => item.id === productId);

      orderProducts.splice(index, 1);

      if (!orderProducts.length) state.orderProducts = [];
    },
  },
};
