<template>
  <OurPage class="users-add" :title="$t('title.users.edit')" :back-route="backRoute" width="md">
    <DeleteModal
      :show.sync="isShownDeleteModal"
      :user-id="userId"
      :full-name="fullName"
      :mutation="deleteEmployeeMutation()"
      @deleteUser="goToList"
    />
    <EmployeeForm
      ref="employeeForm"
      v-model="form"
      class="user-form"
      :user-deleted="isUserDeleted"
      :roles="roles"
      :show-password="false"
    />
    <template #footer-left>
      <OurButton
        v-if="getPermission['employee.update']"
        class="button"
        :text="$t('button.save')"
        @click="onClickSaveButton"
      />

      <OurLink :route="backRoute">
        <OurButton class="button" :text="$t('button.back')" variant="secondary" />
      </OurLink>
    </template>

    <template #footer-right>
      <OurButton
        v-if="!isUserDeleted && getPermission['employee.delete']"
        class="button"
        variant="thirdary"
        :text="$t('button.remove')"
        @click="onClickDeleteButton"
      />
    </template>
  </OurPage>
</template>

<script>
import EmployeeForm from "@/views/settings/users/_components/EmployeeForm.vue";
import DeleteModal from "@/components/modals/DeleteModal.vue";
import apolloMixin from "@/mixins/apollo.mixin";
import { employeeQuery } from "@/views/settings/users/edit/gql/queries";
import { updateEmployeeMutation } from "@/views/settings/users/edit/gql/mutations";
import { deleteEmployeeMutation } from "@/views/settings/users/delete/gql/mutations";
import { mapGetters } from "vuex";

export default {
  name: "UsersEdit",
  components: { DeleteModal, EmployeeForm },
  mixins: [apolloMixin],
  data() {
    return {
      roles: [],
      isShownDeleteModal: false,
      isUserDeleted: false,
      backRoute: {
        name: "Users",
        title: this.$t("title.users._"),
      },
      form: {
        userId: "",
        firstName: "",
        lastName: "",
        email: "",
        isActive: false,
        roleId: "",
      },
    };
  },
  computed: {
    ...mapGetters("user", ["getPermission"]),
    userId() {
      return this.$route.params.id;
    },

    fullName() {
      const { firstName, lastName } = this.form;

      return `${lastName} ${firstName}`;
    },
  },
  created() {
    this.getData();
  },
  methods: {
    deleteEmployeeMutation() {
      return deleteEmployeeMutation;
    },
    goToList() {
      this.$router.push({ name: "Users" });
    },
    onClickDeleteButton() {
      this.isShownDeleteModal = true;
    },
    async getData() {
      const userId = this.userId;
      const { employee, roles: employeeRoles } = await this.$get(employeeQuery, { userId: userId });

      if (employee) {
        const { isActive, firstName, lastName, email, isDeleted, roles } = employee;

        this.isUserDeleted = isDeleted;

        this.form = {
          userId,
          firstName,
          lastName,
          email,
          isActive: isActive,
          roleId: roles[0].id,
        };

        if (employeeRoles) {
          this.roles = employeeRoles
            .filter((role) => role.parent?.code === "employee")
            .map((role) => {
              const { id, name } = role;

              return {
                id,
                label: name,
              };
            });
        }
      }
    },
    async onClickSaveButton() {
      this.$refs.employeeForm.emitForm();

      if (this.form.isValid) {
        const form = {
          ...this.form,
          isActive: this.form.isActive,
        };

        const { updateEmployee } = await this.$post(updateEmployeeMutation, form, {
          delaySuccessNotify: true,
        });

        if (updateEmployee.id) this.goToList();
      }
    },
  },
};
</script>

<style scoped lang="postcss">
.users-add {
  @apply relative;

  .user-form {
    @apply mb-6;
  }
}
</style>
